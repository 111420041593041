<template>
  <el-container>
    <header class="page-header">
      <el-header class="header-menu">
        <head-bar
          :showCollapse="false" 
          :activeValue="active"></head-bar>
      </el-header>
      <div class="content-header">
        <el-form :inline="true" size="small" label-width="80px">
          <el-form-item label="订单状态">
            <el-select clearable v-model="search.searchFields.check_status" placeholder="请选择">
              <el-option
                v-for="item in order_status_list"
                :key="item.code"
                :label="item.value"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="小票编号" >
            <el-input clearable v-model="search.searchFields.receipt_number" placeholder="请输入小票编号"></el-input>
          </el-form-item>
        </el-form>
        <el-button class="search-button" type="primary" icon="el-icon-search" size="small" @click="getOrderList(1)">查询</el-button>
      </div>
    </header>
    <el-container>
      <div class="content">
        <div class="main" ref="main">
          <el-card class="item" v-for="(item, index) of list" :key="item.id" :ref="`item${item.id}`">
            <el-descriptions :title="item.receipt_number" :column="2" size="small">
              <el-descriptions-item label="所属BA">{{item.name}}</el-descriptions-item>
              <el-descriptions-item label="订单状态">{{checkStatus(item.check_status)}}</el-descriptions-item>
              <el-descriptions-item label="购买日期">{{formatDate(Number(item.buy_time) * 1000, 'YYYY.MM.DD').dateString}}</el-descriptions-item>
              <el-descriptions-item label="提交日期">{{formatDate(Number(item.create_time) * 1000, 'YYYY.MM.DD').dateString}}</el-descriptions-item>
              <el-descriptions-item label="参考金额">{{item.reference_money}}</el-descriptions-item>
              <el-descriptions-item label="实发金额">{{item.actual_money}}</el-descriptions-item>
              <el-descriptions-item label="店铺编号">{{item.shop_number}}</el-descriptions-item>
              <el-descriptions-item label="店铺名称">{{item.shop_name}}</el-descriptions-item>
            </el-descriptions>
            <div class="foot" @click="handleDetail(item, index)">>>> 详情</div>
          </el-card>
        </div>
      </div>
    </el-container>
  </el-container>
</template>
<script>
import headBar from '@/components/frames/mobileHeadBar'
import {throttle, formatDate} from '@/libs/utils'
import { BAApi } from '@/api'
import QueryString from 'qs'

export default {
  data: () => ({
    search: {
      searchFields: {
        receipt_from: '1',
        check_status: '',
        receipt_number: '',
        page: 1,
        count: 20,
        myList: [],
        hasMore: true,
        noData: false,
        type: 0
      }
    },
    active: 'home',
    list: [],
    showDetail: false,
    order_status_list: [
      {
        code: 0,
        value: '全部'
      },
      {
        code: 1,
        value: '审核中'
      },
      {
        code: 2,
        value: '审核通过'
      },
      {
        code: 3,
        value: '审核拒绝'
      }
    ],
  }),
  components: {
    headBar
  },

  watch: {
    $route() {
      this.innit()
    }
  },
  
  methods: {
    formatDate: formatDate,
    checkStatus: value => {
      switch (Number(value)) {
        case 1:
          return '待审核'
        case 2:
          return '审核通过'
        case 3:
          return '审核作废'
        case 4:
          return '审核拒绝'
        default:
          break
      }
    },

    innit() {
      const query = this.$route.query
      this.search.searchFields.receipt_from = query.active === 'home'? '1' : '2'
      this.active = query.active
      this.list = []
      this.search.searchFields = Object.assign(this.search.searchFields, {
        page: 1,
        count: 20,
        myList: [],
        hasMore: true,
        noData: false
      })
      this.getOrderList()
    },

    // detail block mount
    async handleDetail(data, index) {
      this.$router.push({
        name: 'mobileDetail',
        query: {
          id: data.id
        }
      })
    },

    // get data
    async getOrderList(page) {
      this.search.searchFields.page = page || this.search.searchFields.page
      let params = {...this.search.searchFields}
      try {
        let data = (await BAApi.getOrderList(params)).data
        if (data.code === 0) {
          this.$validator.reset()
          if (this.search.searchFields.page === 1) {
            this.search.searchFields.myList = []
          }
          const newList = data.receipt_list || []
          if (newList.length >= this.search.searchFields.count) {
            this.search.searchFields.hasMore = true
          } else {
            this.search.searchFields.hasMore = false
          }
          this.search.searchFields.myList = this.search.searchFields.myList.concat(newList)
          if (this.search.searchFields.myList.length === 0 && this.search.searchFields.page === 1) {
            this.search.searchFields.noData = true
          } else {
            this.search.searchFields.noData = false
          }
          this.list = this.search.searchFields.myList
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      }
    },
  },

  beforeMount() {
    this.innit()
  },

  mounted() {
    window.scrollTo(0, 1)
    let that = this
    window.onscroll = throttle(function () {
      const scrollHeight = document.body.scrollHeight
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      const offsetHeight = document.body.offsetHeight
      if (scrollHeight - (scrollTop + offsetHeight) <= 150  && that.search.searchFields.hasMore) {
        that.search.searchFields.page ++
        that.getOrderList()
      }
    }, 1000, true)
  },
  beforeDestroy() {
    window.onscroll = null
  }
}
</script>

<style lang="less" scoped>
.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 9;
  .content-header {
    margin-top: 15px;
    overflow: hidden;
    .search-button {
      float: right;
      margin-right: 15px;
    }
  }
  .header-menu {
    height: auto!important;
  }
}
.content {
  margin-top: 270px;
  .main {
    padding: 15px;
    .item {
      margin-bottom: 15px;
      .foot {
        height: 25px;
        padding-top: 10px;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: #409EFF;
      }
    }
  }
}
.content-header /deep/ .el-select {
  width: 260px;
}
.content-header /deep/ .el-input {
  width: 260px;
}
</style>


